import countries from "@/utils/countries";

export const statusOptions = [
  { label: "Actiu", value: true },
  { label: "Inactiu", value: false },
];

export const realEstateStatusOptions = [
  { label: "En venda", value: "FOR_SALE" },
  { label: "Venut", value: "SOLD" },
  { label: "Venut per 3rs", value: "SOLD_BY_OTHERS" },
  { label: "En lloguer", value: "FOR_RENT" },
  { label: "En lloguer amb opció a compra", value: "FOR_RENT_SALE" },
  { label: "Llogat", value: "RENTED" },
  { label: "Llogat per 3rs", value: "RENTED_BY_OTHERS" },
  { label: "Inactiu", value: "DISABLED" },
  { label: "Esborrany", value: "DRAFT" },
];

export const bookingStatusOptions = [
  { label: "Sol·licitada", value: "REQUESTED" },
  { label: "Confirmada", value: "CONFIRMED" },
  { label: "Completada", value: "COMPLETED" },
  { label: "Cancel·lada", value: "CANCELLED" },
];

export const contractStatusOptions = [
  { label: "Esborrany", value: "DRAFT" },
  { label: "Pendent de signatura", value: "PENDING_SIGNATURE" },
  { label: "Actiu", value: "ACTIVE" },
  { label: "Pendent de renovació", value: "PENDING_RENEWAL" },
  { label: "Finalitzat", value: "TERMINATED" },
];

export const accommodationTypeOptions = [
  { label: "Villa", value: "VILLA" },
  { label: "Xalet", value: "CHALET" },
  { label: "Casa de poble", value: "TOWN_HOUSE" },
  { label: "Casa de camp", value: "COUNTRY_HOUSE" },
  { label: "Apartament", value: "APARTMENT" },
  { label: "Aparthotel", value: "APARTHOTEL" },
  { label: "Altre", value: "OTHER" },
];

export const realEstateAccommodationTypeOptions = [
  { label: "Villa", value: "VILLA" },
  { label: "Xalet", value: "CHALET" },
  { label: "Casa de camp", value: "COUNTRY_HOUSE" },
  { label: "Casa de poble", value: "TOWN_HOUSE" },
  { label: "Apartament", value: "APARTMENT" },
  { label: "Solar", value: "PLOT" },
  { label: "Finca rústica (edificable)", value: "BUILDABLE_RUSTIC_LAND" },
  {
    label: "Finca rústica (no edificable)",
    value: "NON_BUILDABLE_RUSTIC_LAND",
  },
  { label: "Garatge", value: "GARAGE" },
  { label: "Altre", value: "OTHER" },
];

export const countryOptions = countries.map((c) => ({
  label: c.name_ca,
  value: c.code,
}));

export const countryOptionsAlpha3 = countries.map((c) => ({
  label: c.name_ca,
  value: c.code_alpha_3,
}));

export const regionOptions = [
  { label: "Mallorca", value: "Mallorca" },
  { label: "Menorca", value: "Menorca" },
  { label: "Eivissa", value: "Eivissa" },
];

export const addressTypeOptions = [
  { label: "Carrer", value: "STREET" },
  { label: "Avinguda", value: "AVENUE" },
  { label: "Camí", value: "PATHWAY" },
  { label: "Carretera", value: "ROAD" },
  { label: "Disseminat", value: "DISSEMINATED" },
  { label: "Via", value: "WAY" },
  { label: "Autovia", value: "FREEWAY" },
  { label: "Plaça", value: "SQUARE" },
  { label: "Boulevard", value: "BOULEVARD" },
  { label: "Passatge", value: "PASSAGE" },
  { label: "Passeig", value: "PROMENADE" },
  { label: "Rambla", value: "RAMBLA" },
  { label: "Pati", value: "COURT" },
  { label: "Altre", value: "OTHER" },
];

export const areaOptions = [
  { label: "Nord", value: "NORTH" },
  { label: "Sud", value: "SOUTH" },
  { label: "Est", value: "EAST" },
  { label: "Oest", value: "WEST" },
  { label: "Centre", value: "CENTER" },
  { label: "Nord-Est", value: "NORTH_EAST" },
  { label: "Nord-Oest", value: "NORTH_WEST" },
  { label: "Sud-Est", value: "SOUTH_EAST" },
  { label: "Sud-Oest", value: "SOUTH_WEST" },
  { label: "Centre-Nord", value: "CENTER_NORTH" },
  { label: "Centre-Sud", value: "CENTER_SOUTH" },
  { label: "Centre-Est", value: "CENTER_EAST" },
  { label: "Centre-Oest", value: "CENTER_WEST" },
];

export const languageOptions = [
  { label: "Català", value: "ca" },
  { label: "Castellano", value: "es" },
  { label: "English", value: "en" },
  { label: "Deutch", value: "de" },
  { label: "Français", value: "fr" },
  { label: "Italiano", value: "it" },
  { label: "Pусский", value: "ru" },
];

export const heatingTypeOptions = [
  { label: "Aire Acondicionat", value: "AIR_CONDITIONING" },
  { label: "Radiadors Elèctrics", value: "ELECTRIC_RADIATORS" },
  { label: "Radiadors d'oli", value: "OIL_RADIATORS" },
  { label: "Radiadors d'aigua", value: "WATER_RADIATORS" },
  { label: "Sòl radiant", value: "FLOOR" },
  { label: "Mixte", value: "MIXED" },
];

export const viewTypeOptions = [
  { label: "Sense vistes", value: "NONE" },
  { label: "Jardí", value: "GARDEN" },
  { label: "Piscina", value: "POOL" },
  { label: "Terrassa", value: "TERRACE" },
  { label: "Platja", value: "BEACH" },
  { label: "Mar", value: "OCEAN" },
  { label: "Muntanya", value: "MOUNTAIN" },
  { label: "Carrer", value: "STREET" },
];

export const bedroomTypeOptions = [
  { label: "Estàndard", value: "STANDARD" },
  { label: "Principal", value: "MASTER" },
  { label: "Zona comuna", value: "COMUNAL" },
  { label: "Obert", value: "OPEN" },
];

export const bedTypeOptions = [
  { label: "Llit individual", value: "SINGLE" },
  { label: "Llit doble", value: "DOUBLE" },
  { label: "Llitera individual", value: "SINGLE_BUNK_BED" },
  { label: "Llitera doble", value: "DOUBLE_BUNK_BED" },
  { label: "Llitera mixta", value: "MIXED_BUNK_BED" },
  { label: "Sofà llit individual", value: "SINGLE_SOFA_BED" },
  { label: "Sofà llit doble", value: "DOUBLE_SOFA_BED" },
];

export const mattressTypeOptions = [
  { label: "Molles", value: "SPRINGS" },
  { label: "Escuma", value: "FOAM" },
  { label: "Latex", value: "LATEX" },
  { label: "Aigua", value: "WATER" },
  { label: "Aire", value: "AIR" },
  { label: "Mixte", value: "MIXED" },
];

export const pillowTypeOptions = [
  { label: "Escuma", value: "FOAM" },
  { label: "Latex", value: "LATEX" },
  { label: "Plomes", value: "FEATHERS" },
];

export const paymentFrequencyOptions = [
  { label: "Pagament únic", value: "ONCE" },
  { label: "Dia/Vespre", value: "DAY" },
  { label: "KW/h", value: "KW" },
  { label: "Litre", value: "LITER" },
  { label: "Hora", value: "HOUR" },
];

export const paymentTimeOptions = [
  { label: "Pagament inicial", value: "INITIAL_PAYMENT" },
  { label: "Pagament final", value: "FINAL_PAYMENT" },
  { label: "Abans d'entrar", value: "BEFORE_CHECK_IN" },
  { label: "A l'entrada", value: "CHECK_IN" },
  { label: "A la sortida", value: "CHECK_OUT" },
  { label: "Al proveïdor", value: "PROVIDER" },
];

export const paymentMethodOptions = [
  { label: "Targeta", value: "CREDIT_CARD" },
  { label: "Targeta virtual", value: "VIRTUAL_CREDIT_CARD" },
  { label: "Transferència", value: "BANK_TRANSFER" },
  { label: "Retenció de fiança", value: "SECURITY_DEPOSIT" },
  { label: "Efectiu", value: "CASH" },
  { label: "PayPal", value: "PAYPAL" },
  { label: "Bizum", value: "BIZUM" },
  { label: "Pagament per portal", value: "SOURCE_PAYMENT" },
];

export const paymentStatusOptions = [
  { label: "Pre-confirmat", value: "PRE_CONFIRMED" },
  { label: "Confirmat", value: "CONFIRMED" },
  { label: "Verificat", value: "VERIFIED" },
  { label: "Comptabilitzat", value: "ACCOUNTED" },
  { label: "Altre", value: "OTHER" },
];

export const otaOptions = [
  {
    label: "101Hotels.com",
    value: "101HOTELS",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Agoda",
    value: "AGODA",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Airbnb",
    value: "AIRBNB",
    highlighted: true,
    selfManagedPayments: true,
  },
  {
    label: "Atraveo",
    value: "ATRAVEO",
    highlighted: true,
    selfManagedPayments: true,
  },
  {
    label: "Booking.com",
    value: "BOOKING",
    highlighted: true,
    selfManagedPayments: false,
  },
  {
    label: "Bungalow",
    value: "BUNGALOW",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Casamundo",
    value: "CASAMUNDO",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "E-Domizil",
    value: "EDOMIZIL",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "eDreams",
    value: "EDREAMS",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Expedia",
    value: "EXPEDIA",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Foravila Rentals",
    value: "FORAVILA",
    highlighted: true,
    selfManagedPayments: false,
  },
  {
    label: "Holidu",
    value: "HOLIDU",
    highlighted: true,
    selfManagedPayments: true,
  },
  {
    label: "HomeAway",
    value: "HOMEAWAY",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "HomeToGo",
    value: "HOMETOGO",
    highlighted: true,
    selfManagedPayments: true,
  },
  {
    label: "Hotelbeds",
    value: "HOTELBEDS",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "HouseTrip",
    value: "HOUSETRIP",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Locasun",
    value: "LOCASUN",
    highlighted: true,
    selfManagedPayments: true,
  },
  {
    label: "Muchosol",
    value: "MUCHOSOL",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Traum-Ferienwohnungen",
    value: "TRAUM",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "TripAdvisor",
    value: "TRIPADVISOR",
    highlighted: true,
    selfManagedPayments: true,
  },
  {
    label: "Vacasol",
    value: "VACASOL",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Villapartner",
    value: "VILLAPARTNER",
    highlighted: false,
    selfManagedPayments: false,
  },
  {
    label: "Vrbo",
    value: "VRBO",
    highlighted: true,
    selfManagedPayments: false,
  },
  {
    label: "Wimdu",
    value: "WIMDU",
    highlighted: false,
    selfManagedPayments: false,
  },
];

export const teamMemberOptions = [
  { label: "Antoni", value: "ANTONI" },
  { label: "Biel", value: "BIEL" },
  { label: "Francisca", value: "FRANCISCA" },
  { label: "Mila", value: "MILA" },
  { label: "Pedro", value: "PEDRO" },
  { label: "Pep", value: "PEP" },
];

export const genderOptions = [
  { label: "Home", value: "MALE" },
  { label: "Dona", value: "FEMALE" },
  { label: "Altre", value: "OTHER" },
];

export const idTypeOptions = [
  { label: "Passaport", value: "PASSPORT" },
  { label: "DNI (Espanyol)", value: "DNI" },
  { label: "NIF (Espanyol)", value: "NIF" },
  { label: "NIE (Espanyol)", value: "NIE" },
  { label: "CIF (Espanyol)", value: "CIF" },
  { label: "Altre (DNI extranger, carnet de conduir, etc.)", value: "OTHER" },
];

export const paymentScopeOptions = [
  { label: "Reserva", value: "BOOKING" },
  { label: "Fiança", value: "SECURITY_DEPOSIT" },
  { label: "Impost turístic", value: "TOURIST_TAX" },
  { label: "Servei", value: "SERVICE" },
  { label: "Altre", value: "OTHER" },
];

export const transportMethodOptions = [
  { label: "Avió", value: "PLANE" },
  { label: "Vaixell", value: "BOAT" },
  { label: "Sense transport", value: "NONE" },
];

export const serviceTypeOptions = [
  { label: "Requeriment", value: "REQUIREMENT" },
  { label: "Extra allotjament", value: "ACCOMMODATION_EXTRA" },
  { label: "Servei de tercers", value: "3RD_PARTY_SERVICE" },
];

export const billingTypeOptions = [
  { label: "Comissió", value: "COMISSION" },
  { label: "Subarrendament", value: "SUBURANCE" },
];

export const managementTypeOptions = [
  { label: "Compartida", value: "SHARED" },
  { label: "Exclusiva", value: "EXCLUSIVE" },
];

export const commentScopeOptions = [
  { label: "General", value: "GENERAL" },
  { label: "Arribada", value: "ARRIVAL" },
  { label: "Comptabilitat", value: "ACCOUNTING" },
  { label: "Fiança", value: "SECURITY_DEPOSIT" },
];

export const bookingIncidenceStatusOptions = [
  { label: "Oberta", value: "OPEN" },
  { label: "Pendent de tercers", value: "PENDING" },
  { label: "Tancada", value: "CLOSED" },
];

export const faqScopeOptions = [
  { label: "Només client", value: "CLIENT" },
  { label: "Només agència", value: "AGENCY" },
  { label: "Tothom", value: "ALL" },
];

export const providerTypeOptions = [
  { label: "Neteja", value: "CLEANING" },
  { label: "Roba de llar", value: "HOUSEHOLD_CLOTHES" },
  { label: "Bugaderia", value: "HOUSEHOLD_CLOTHES_CLEANING" },
  { label: "Gestió d'incidències", value: "INCIDENCES" },
  { label: "Manteniment de piscina", value: "SWIMMING_POOL" },
  { label: "Manteniment de jardí", value: "GARDEN" },
];

export const placeTypeOptions = [
  { label: "Aeroport", value: "AIRPORT" },
  { label: "Caixer automàtic", value: "ATM" },
  { label: "Bar", value: "BAR" },
  { label: "Platja", value: "BEACH" },
  { label: "Aturada de bus", value: "BUS" },
  { label: "Ciutat", value: "CITY" },
  { label: "Discoteca", value: "CLUB" },
  { label: "Hotel", value: "HOTEL" },
  { label: "Mercat", value: "MARKET" },
  { label: "Monument", value: "MONUMENT" },
  { label: "Parc", value: "PARK" },
  { label: "Port", value: "PORT" },
  { label: "Restaurant", value: "RESTAURANT" },
  { label: "Aturada de taxi", value: "TAXI" },
  { label: "Altre", value: "OTHER" },
];

export const invoiceHolderOptions = [
  { label: "Nosaltres", value: "AGENCY" },
  { label: "Propietari", value: "OWNER" },
  { label: "Proveïdor", value: "PROVIDER" },
];

export const vatPercentageOptions = [
  { label: "0%", value: 0 },
  { label: "4%", value: 4 },
  { label: "10%", value: 10 },
  { label: "21%", value: 21 },
];

export const irpfPercentageOptions = [
  { label: "0%", value: 0 },
  { label: "7%", value: 7 },
  { label: "15%", value: 15 },
  { label: "19%", value: 19 },
  { label: "21%", value: 21 },
];

export const keysLocationOptions = [
  { label: "Lockbox (barrera)", value: "LOCKBOX_BESIDE_MAIN_GATE" },
  { label: "Lockbox (porta casa)", value: "LOCKBOX_BESIDE_HOUSE_DOOR" },
  {
    label: "Lockbox (porta casa, barrera oberta)",
    value: "LOCKBOX_BESIDE_HOUSE_DOOR_OPEN_GATE",
  },
  { label: "Davall l'estora", value: "UNDER_DOORMAT" },
  { label: "Dintre del cossiol", value: "INSIDE_PLANT_POT" },
  { label: "Altre", value: "OTHER" },
];

export const promotionCategoryOptions = [
  { label: "Estàndard", value: "STANDARD" },
  { label: "Last minute", value: "LAST_MINUTE" },
  { label: "Reserva anticipada", value: "EARLY_BOOKING" },
  { label: "Codi promocional", value: "PROMO_CODE" },
];

export const promotionTypeOptions = [
  { label: "Descompte (%)", value: "PERCENTAGE_DISCOUNT" },
  { label: "Descompte (€)", value: "AMOUNT_DISCOUNT" },
  { label: "Preu fixe (€)", value: "FIXED_PRICE" },
  { label: "Nits gratuïtes", value: "FREE_NIGHTS" },
];

export const promotionScopeOptions = [
  { label: "Reserva", value: "BOOKING" },
  { label: "Setmana", value: "WEEK" },
  { label: "Nit", value: "NIGHT" },
  { label: "Reserva/Hoste", value: "BOOKING_GUEST" },
  { label: "Setmana/Hoste", value: "WEEK_GUEST" },
  { label: "Nit/Hoste", value: "NIGHT_GUEST" },
];

export const weekdayOptions = [
  { label: "Dilluns", value: 1 },
  { label: "Dimarts", value: 2 },
  { label: "Dimecres", value: 3 },
  { label: "Dijous", value: 4 },
  { label: "Divendres", value: 5 },
  { label: "Dissabte", value: 6 },
  { label: "Diumenge", value: 0 },
];

export const acceptedBookingTypeOptions = [
  { label: "Directa", value: "DIRECT" },
  { label: "Petició", value: "REQUEST" },
];

export const realEstateOperations = [
  { label: "Compra", value: "BUY" },
  { label: "Lloguer", value: "RENT_REQUEST" },
  { label: "Venda", value: "SELL" },
  { label: "Oferta de lloguer", value: "RENT_OFFER" },
];

export const parentageTypeOptions = [
  { label: "Mare/pare", value: "PM" },
  { label: "Germà/germana", value: "HR" },
  { label: "Oncle/tia", value: "TI" },
  { label: "Avi/àvia", value: "AB" },
  { label: "Besavi/besàvia", value: "BA" },
  { label: "Tutor/a", value: "TU" },
  { label: "Altres", value: "OT" },
];
